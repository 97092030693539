/*------ shop page style start ------*/
.shop-top-bar {
    margin-bottom: 30px;
}
.top-bar-left {
    display: flex;
    align-items: center;
    @media #{$sm-device} {
        padding-top: 15px;
        justify-content: space-between;
    }
    .product-view-mode {
        margin-right: 60px;
        @media #{$xxs-device} {
            margin-right: 0;
        }
        a {
            color: #b6b6b6;
            display: inline-block;
            text-align: center;
            margin-right: 10px;
            i {
                font-size: 20px;
                margin-top: 6px;
            }
            &:hover {
                color: $theme-color;
            }
            &.active {
                color: $theme-color;
            }
        }
    }
    .product-amount {
        p {
            color: $white;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
}
.top-bar-right {
    display: flex;
    justify-content: flex-end;
    @media #{$sm-device} {
        justify-content: center;
    }
    .product-short {
        display: flex;
        align-items: center;
        p {
            margin-right: 10px;
        }
    }
}
// nice select
.nice-select {
    height: 36px;
    line-height: 34px;
    width: 200px;
    padding: 0 10px;
    border-color: $bdr-color;
    background-color: transparent;
    // Responsive
    @media #{$lg-device} {
        width: 170px;
    }
    @media #{$xxs-device} {
        width: 190px;
    }
    &.open {
        border-color: $theme-color;
    }
    .list {
        right: 0;
        left: auto;
        width: 100%;
        background-color: $bg-dark;
    }
    .option {
        font-size: 13px;
        line-height: 34px;
        min-height: 34px;
        text-transform: capitalize;
        &:hover,
        &.focus {
            background-color: $bg-dark-soft !important;
        }
    }
    &:after {
        height: 6px;
        width: 6px;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
    }
    &:hover {
        border-color: $bdr-color;
    }
}
/*----- shop main wrapper end -----*/

/*----- product change view start -----*/
.shop-product-wrap {
    &.list-view {
        .product-item {
            display: none;
        }
    }
}

.shop-product-wrap {
    &.grid-view {
        .product-item {
            display: block;
            animation-name: zoomIn;
            animation-duration: 1s;
            padding: 0;
        }
    }
}

.shop-product-wrap {
    &.grid-view {
        .product-list-item {
            display: none !important;
        }
    }
}
.shop-product-wrap {
    &.list-view {
        .product-list-item {
            display: flex;
            animation-name: fadeInRight;
            animation-duration: 1s;
        }
    }
}

.shop-product-wrap {
    &.row {
        &.list-view {
            .col-md-4 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

.shop-product-wrap {
    [class*="col-"] {
        margin-bottom: 30px;
    }
}
/*----- product change view end -----*/

/*------ shop page style start ------*/