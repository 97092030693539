/*------ Start Login & Register Page ------*/
.login-reg-form-wrap {
    background-color: $bg-dark-soft;
    box-shadow: (0 0 6px rgba(0, 0, 0, 0.1));
    padding: 23px 30px 30px;
    // responsive
    @media #{$xs-device} {
        padding: 23px 15px 30px;
    }

    form {
        .create-account {
            margin-top: 25px;
        }
    }

    & .login-reg-form-meta {
        & a {
            color: $theme-color;
        }
    }
}
.sign-up-form {
    // responsive
    @media #{$md-device} {
        margin-top: 80px;
    }
    @media #{$sm-device} {
        margin-top: 62px;
    }
}

/*------ end Login & Register Page ------*/