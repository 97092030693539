/* Checkout Login Coupon Accordion Start */
.checkout-page-wrapper {
    margin-bottom: -8px;
    // responsive
    @media #{$md-device, $sm-device} {
        margin-bottom: 0;
    } 
}
.checkoutaccordion {
    margin-bottom: 46px;
    & h6 {
        background-color: $bg-dark-soft;
        border-top: 3px solid $theme-color;
        font-size: 14px;
        padding: 15px 20px;
        position: relative;
        text-transform: capitalize;

        span {
            color: $theme-color;
            cursor: pointer;
            transition: 0.4s;
            margin-left: 10px;
            // responsive
            @media #{$xxs-device} {
                display: block;
                padding-top: 5px;
                margin-left: 0;
            }

            &:hover {
                color: $white;
            }
        }

        @media #{$xxs-device} {
            font-size: 14px;
        }
    }

    .card {
        border: none;
        padding: 0;
        transition: 0.4s;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }

        .card-body {
            border: 1px solid $bdr-color;
            font-size: 14px;
            padding: 20px;
            background-color: $bg-dark;

            .cart-update-option {
                border: none;
                padding: 0;

                .apply-coupon-wrapper {
                    input {
                        padding: 12px 10px;
                        background-color: $bg-dark-soft;
                        border: 1px solid $bdr-color;
                        margin-right: 15px;

                        @media #{$xxs-device} {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

// checkout box wrap 
.checkout-box-wrap {
    .custom-control {
        padding-left: 0;
        margin-left: 1.5rem;
    }
}

// Checkout Billing Details
.checkout-billing-details-wrap {
    .billing-form-wrap {
        margin-top: -20px;
    }
}
.checkout-title {
    border-bottom: 1px solid $bdr-color;
    padding-bottom: 15px;
    margin-bottom: 30px;
    // responsive
    @media #{$sm-device} {
        margin-bottom: 26px;
    }
}

.forget-pwd {
    color: $theme-color;
    font-size: 12px;
    margin-top: -6px;
}

.single-form-row {
    margin-top: 15px;
    display: none;

    p {
        margin: 0;
        font-size: 14px;
    }
}

//Order Summary Detail
.order-summary-details {
    // responsive
    @media #{$md-device} {
        margin-top: 70px;
    }
    @media #{$sm-device} {
        margin-top: 52px;
    }
}

.order-summary-table {
    background-color: $bg-dark-soft;
    margin-top: 34px;

    .table,
    table {
        color: $paragraph;
        margin-bottom: 0;
        font-size: 15px;
        white-space: nowrap;

        tr {

            td,
            th {
                font-weight: 400;
                vertical-align: middle;
                padding: 15px 10px;
                border-width: 1px;
                border: 1px solid $bdr-color;

                a {
                    color: $paragraph;
                    font-weight: 400;
                }

                & strong {
                    font-weight: 400;
                }
            }
        }
    }

    .shipping-type {
        text-align: left;

        li {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

// Order Payment Method
.order-payment-method {
    background-color: $bg-dark-soft;
    padding: 40px 20px 30px;
    // responsive
    @media #{$md-device, $sm-device} {
        padding: 32px 20px 36px;
    }
}

.single-payment-method {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        .payment-method-details {
            display: block;
        }
    }

    label,
    .custom-control-label {
        font-weight: 700;
    }

    .paypal-card {
        max-width: 150px;
        height: 50px;
        margin-top: 10px;
        display: block;
    }

    .payment-method-details {
        background-color: $bg-dark;
        color: $paragraph;
        font-weight: 400;
        font-size: 13px;
        padding: 10px;
        position: relative;
        margin-top: 20px;
        display: none;

        &:after {
            content: '';
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $bg-dark;
            bottom: 100%;
        }

        * {
            margin: 0;
        }
    }
}

.summary-footer-area {
    .btn-brand {
        margin-top: 40px;
    }
}


/*------- input box style css start -------*/
.single-input-item {
    margin-top: 20px;

    label {
        color: $white;
        text-transform: capitalize;
        font-size: 14px;

        &.required {
            &:after {
                content: '*';
                color: red;
                font-size: 14px;
                margin-left: 3px;
                margin-top: 5px;
            }
        }
    }

    & input,
    textarea {
        color: $paragraph;
        border: 1px solid $bdr-color;
        padding: 12px 10px;
        width: 100%;
        font-size: 14px;
        background: $bg-dark-soft;

        &:active,
        &:focus {
            border-color: $theme-color;
            background-color: $bg-dark-soft;
        }
    }

    .nice-select {
        width: 100%;
        border-radius: 0;
        height: 48px;
        border-color: $bdr-color;
        background-color: $bg-dark-soft;

        .current {
            color: $paragraph;
        }

        .list {
            max-height: 200px;
            overflow: auto;
            width: 100%;
        }
    }
}
/*------- input box style css end -------*/


