/*----- testimonial area start -----*/
.testimonial-area {
    .section-title {
        .title {
            background: linear-gradient(90deg, #c29958, #fff, #c29958);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0, 0, 0, 0);
        }
        .sub-title {
            color: $white;
        }
    }
}
.testimonial-thumb-wrapper {
    max-width: 290px;
    margin: auto;
}
.testimonial-thumb {
    cursor: pointer;
    transform: scale(0.7);
    transition: 0.4s;
    width: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
.slick-slide {
    &.slick-current {
        .testimonial-thumb {
            transform: scale(1);
        }
    }
}
.testimonial-content-wrapper {
    max-width: 910px;
    margin: auto;
    margin-top: 25px;
    // responsive
    @media #{$lg-device} {
        max-width: 750px;
    }
}
.testimonial {
    &-content {
        padding: 0 15px;
        text-align: center;
        p {
            color: $white;
            font-size: 15px;
        }
        .ratings {
            margin-top: 30px;
            margin-bottom: 5px;
        }
    }
    &-author {
        color: $white;
        font-size: 17px;
        font-weight: 700;
        line-height: 1;
        text-transform: capitalize;
    }
}
/*----- testimonial area end -----*/