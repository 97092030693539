/*------ group list item start ------*/
.group-product-banner {
    @media #{$md-device, $sm-device} {
        margin-bottom: 80px;
    }
}

.group-item {
    display: flex;
    &-thumb {
        max-width: 80px;
        flex-basis: 80px;
    }
    &-desc {
        position: relative;
        padding-left: 10px;
        max-width: calc(100% - 80px);
        flex-basis: calc(100% - 80px);
        .group-product-name {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;
            text-transform: capitalize;
            padding-bottom: 18px;
            a {
                color: $white;
                &:hover {
                    color: $theme-color;
                    text-decoration: underline;
                }
            }
        }
    }
}

.categories-group-wrapper {
    @media #{$md-device} {
        margin-bottom: 78px;
    }
    @media #{$sm-device} {
        margin-bottom: 60px;
    }
}
.row {
    [class*="col-"] {
        &:last-child {
            .categories-group-wrapper {
                margin-bottom: 0;
            }
        }
    }
}

.group-list-item-wrapper {
    .slick-slider {
        .slick-slide> {
            div {
                margin-bottom: 20px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
/*------ group list item end ------*/


