
/*-------- off canvas mini cart start --------*/
.minicart-inner {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transition: 0.4s;
    z-index: 9;
    opacity: 0;
    visibility: hidden;

    .offcanvas-overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        cursor: url('../img/icon/cancel.png'), auto;
    }

    &.show {
            opacity:1;
        visibility: visible;

        .minicart-inner-content{
            transform: none;
        }
    }
    // minicart close button
    .minicart-close {
        width: 50px;
        height: 50px;
        text-align: center;
        background-color: $theme-color;
        color: $white;
        font-size: 50px;
        cursor: pointer;
        top: 0;
        right: 375px;
        position: absolute;
        i {
            display: block;
            line-height: 50px;
            transition: 0.6s;
        }
        &:hover {
            i {
                transform: rotate(180deg);
            }
        }
    }
    .minicart-inner-content {
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        max-width: 375px;
        position: absolute;
        background-color: $black-soft;
        transform: translateX(calc(100% + 50px));
        transition: 0.4s;
        padding: 50px 20px;
    }
}

.minicart-content-box {
    overflow: auto;
    height: 100%;
    padding-right: 30px;
    margin-right: -15px;
}
.minicart-item-wrapper {
    border-bottom: 1px solid $bdr-color;
    ul {
        li {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $bdr-color;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}

.minicart {
    &-item {
        display: flex;
        flex-wrap: wrap;
    }
    &-thumb {
        max-width: 85px;
        flex-basis: 85px;
    }
    &-content {
        padding: 0 10px;
        max-width: calc(100% - 115px);
        flex-basis: calc(100% - 115px);
        .product-name {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.5;
            padding-bottom: 10px;
            a {
                color: $white;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        .cart-quantity {
            font-size: 12px;
            color: $paragraph;
            line-height: 1;
            strong {
                font-size: 16px;
                font-weight: 400;
                vertical-align: text-bottom;
            }
        }
        .cart-price {
            color: $theme-color;
            font-size: 14px;
            line-height: 1;
        }
    }
    &-remove {
        max-width: 30px;
        flex-basis: 30px;
        text-align: center;
        font-size: 18px;
        line-height: 1;
        color: $white;
        &:hover {
            color: $theme-color;
        }
    }
}

// minicart pricing box start
.minicart-pricing-box {
    border-bottom: 1px solid $bdr-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-top: 15px;
    li {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        span {
            font-size: 14px;
            color: $white;
            text-transform: capitalize;
            strong {
                color: $theme-color;
                font-weight: 400;
            }
        }
        &.total {
            span {
                font-size: 16px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// minicart button
.minicart-button {
    a {
        color: $black-soft;
        font-size: 14px;
        display: block;
        font-weight: 700;
        line-height: 1;
        padding: 17px 0;
        background-color: #f3f3f3;
        border-radius: 40px;
        text-align: center;
        margin-bottom: 10px;
        i {
            padding-right: 5px;
        }
        &:hover {
            color: $black-soft;
            letter-spacing: 1.1px;
            background-color: $theme-color;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
/*-------- off canvas mini cart end --------*/