/*----- breadcrumb style css start -----*/
.breadcrumb-area {
    background-color: $bg-dark-soft;
}
.breadcrumb-wrap {
    padding: 24px 0;
    text-align: center;
    .breadcrumb {
        background: transparent;
        margin-bottom: 0;
        padding: 0;
        display: flex;
        align-items: center;
        .breadcrumb-item {
            a {
                color: $white;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                text-transform: capitalize;
                &:hover {
                    color: $theme-color;
                }
            }
            &:before {
                color: #7e7e7e;
                content: "/";
                font-size: 12px;
                margin: 0 5px;
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
            &.active {
                color: $theme-color;
                font-size: 14px;
                font-weight: 400;
                text-transform: capitalize;
            }
        }
    }
}
/*----- breadcrumb style css end -----*/