/*------ Typography Style Start ------*/

body {
    color: $paragraph;
    line-height: 1.7;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-family-base;
}

a {
    transition: 0.4s;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }
}

p {
    margin-bottom: 8px; 
    &:last-child {
        margin-bottom: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font;
    color: $white;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
}
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    line-height: 1.5;
}
h1, .h1 {
    font-size: 36px;
}
h2, .h2 {
    font-size: 30px;
}
h3, .h3 {
    font-size: 24px;
}
h4, .h4 {
    font-size: 20px;
}
h5, .h5 {
    font-size: 18px;
}
h6, .h6 {
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

strong,
b {
    font-weight: 700;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    transition: 0.4s;
}

.bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.btn,
button {
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    box-shadow: none;
    transition: 0.4s;
    background-color: transparent;
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

input,
textarea {
    resize: none;

    &:focus {
        outline: none;
    }
}
.form-control {
    &:focus {
        border-color: $theme-color;
        box-shadow: none;
    }
}

// placeholder text style
::placeholder {
    color: #999;
    font-size: 13px;
    opacity: 1;
}

/* ----tab problem fix css ----*/
.tab-content {
    .tab-pane {
        display: block;
        height: 0;
        max-width: 100%;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;

        &.active {
            height: auto;
            opacity: 1;
            overflow: visible;
            visibility: visible;
        }
    }
}
/* ----tab problem fix css ----*/

/*------- modal fix start -------*/
.modal-dialog {
    max-width: 1000px;

    // responsive
    @media #{$lg-device} {
        max-width: 800px;
    }

    @media #{$md-device} {
        max-width: 700px;
    }
}

.modal {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -99;

    &.show {
        opacity: 1;
        visibility: visible;
        pointer-events: visible;
        z-index: 99999999;
        -webkit-animation: slideInDown 0.4s forwards;
        animation: slideInDown 0.4s forwards;
    }

    .modal-header {
        padding: 0 10px;
        border-bottom: none;

        .close {
            color: $white;
            font-size: 34px;
            opacity: 1;
            display: block;
            position: absolute;
            padding: 0;
            width: 30px;
            height: 30px;
            margin: 0;
            right: 10px;
            top: 10px;
            font-weight: 500;
            z-index: 999;
            line-height: 30px;
            border-radius: 50%;
        }
    }

    .modal-body {
        padding: 20px 20px 20px 20px;

        @media #{$xxs-device} {
            padding: 10px;
        }
    }
}
/*------- modal fix end -------*/

/* ---- Sick Slider arrow style start ----*/
.slick-slider {
    .slick-slide>div>div {
        vertical-align: middle;
    }
}
// slick arrow style
.slick-arrow-style {
    button {
        &.slick-arrow {
            top: 50%;
            left: 0;
            font-size: 70px;
            color: $paragraph-light;
            cursor: pointer;
            position: absolute;
            transform: translateY(-50%);
            line-height: 1;
            opacity: 0;
            visibility: hidden;
            transition: 0.4s;

            &.slick-next {
                left: auto;
                right: 0;
            }

            &:hover {
                color: $theme-color;
            }
        }
    }

    &:hover {
        button {
            &.slick-arrow {
                opacity: 1;
                visibility: visible;
                transform: translateY(-50%);
                z-index: 1;
                &.slick-prev {
                    left: -60px;
                    right: auto;
                    // responsive
                    @media #{$xlmid-device, $lg-device, $md-device, $sm-device} {
                        left: -20px;
                    }
                }
                &.slick-next {
                    left: auto;
                    right: -60px;
                    // responsive
                    @media #{$xlmid-device, $lg-device, $md-device, $sm-device} {
                        right: -20px;
                    }
                }
            }
        }
    }

    &_hero {
        button {
            &.slick-arrow {
                font-size: 60px;
                left: 0;
                width: inherit;
                height: inherit;
                background-color: transparent;
                z-index: 1;
                &.slick-next {
                    right: 0;
                }

                &:hover {
                    color: $theme-color;
                    background-color: transparent;
                }
            }
        }
        &:hover {
            button {
                &.slick-arrow {
                    &.slick-next {
                        right: 15px;
                    }
                    &.slick-prev {
                        left: 15px;
                    }
                }
            }
        }
    }
}

// slick append style
.slick-append {
    background-color: $white;
    z-index: 2;
    margin-top: -2px;
    button {
        font-size: 30px;
        line-height: 1;
        position: inherit;
        display: inline-block;
        background-color: $bg-dark;
        color: $paragraph;

        &:hover {
            color: $theme-color;
        }
    }
}


/*--------- slick slider dot style start -------*/
.slick-dot-style {
    ul {
        &.slick-dots {
            bottom: 15px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);

            li {
                display: inline-block;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                button {
                    width: 14px;
                    height: 14px;
                    padding: 0;
                    border: none;
                    display: block;
                    text-indent: -5000px;
                    cursor: pointer;
                    border-radius: 50%;
                    transition: 0.4s;
                    border: 2px solid $paragraph-light;
                    background-color: transparent;
                }

                &.slick-active button {
                    border-color: $theme-color;
                    background-color: $theme-color;
                }
            }
        }
    }
}

// Slick Slider Gutters
@for $i from 3 through 15 {
    .slick-row-#{1 * $i} {
        .slick-list {
            margin: 0 #{-1px * $i};

            .slick-slide {
                margin: 0 #{1px * $i};
            }
        }
    }

    @media #{$sm-device} {
        .slick-sm-row-#{1 * $i} {
            .slick-list {
                margin: 0 #{-1px * $i} !important;

                .slick-slide {
                    margin: 0 #{1px * $i} !important;
                }
            }
        }
    }
}

.slick-slider {
    .slick-slide> {
        div {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/* ----scroll to top css start ----*/
.scroll-top {
    bottom: 50px;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 50px;
    z-index: 9999;
    transition: 0.4s;
    border-radius: 50%;
    background-color: $theme-color;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    // responsive
    @media #{$sm-device} {
        display: none;
    }

    i {
        line-height: 50px;
        color: #fff;
        font-size: 25px;
    }

    &.not-visible {
        bottom: -50px;
        visibility: hidden;
        opacity: 0;
    }

    &:hover {
        background-color: $white;
        i {
            color: $black-soft;
        }
    }
}

/* ----scroll to top css end ----*/

// custom container
.custom-container {
    max-width: 1800px;
}

// custom row
@for $i from 0 through 30 {
    .row-#{1 * $i} {
        margin-left: -0.5px * $i;
        margin-right: -0.5px * $i;

        &>[class*="col"] {
            padding-left: 0.5px * $i;
            padding-right: 0.5px * $i;
        }
    }
}